import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.app/gatsby-image
 * - `StaticQuery`: https://gatsby.app/staticquery
 */

export const PhoneImage = ({ identifier }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          phoneNL: file(relativePath: { eq: "netherlands-app.PNG" }) {
            childImageSharp {
              fixed(width: 400) {
                # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                ...GatsbyImageSharpFixed
              }
            }
          }
          phoneBENL: file(relativePath: { eq: "belgium-dutch.PNG" }) {
            childImageSharp {
              fixed(width: 400) {
                # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                ...GatsbyImageSharpFixed
              }
            }
          }
          phoneBEFR: file(relativePath: { eq: "belgium-french.PNG" }) {
            childImageSharp {
              fixed(width: 400) {
                # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <Img
            imgStyle={{ objectFit: 'contain' }}
            fadeIn={false}
            fixed={data[identifier].childImageSharp.fixed}
          />
        )
      }}
    />
  )
}

export default PhoneImage
