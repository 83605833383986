import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.app/gatsby-image
 * - `StaticQuery`: https://gatsby.app/staticquery
 */

export const StoreIcon = ({ identifier }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          ios: file(relativePath: { eq: "app-store.png" }) {
            childImageSharp {
              fixed(width: 150, quality: 90) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          android: file(relativePath: { eq: "play-store.png" }) {
            childImageSharp {
              fixed(width: 150, quality: 90) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <Img fadeIn={false} fixed={data[identifier].childImageSharp.fixed} />
        )
      }}
    />
  )
}

export default StoreIcon
