import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styles from './image.module.css'

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.app/gatsby-image
 * - `StaticQuery`: https://gatsby.app/staticquery
 */

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1600, maxHeight: 800) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }
`

export const Image = ({ identifier }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          frenchHero: file(relativePath: { eq: "belgium-hero.jpg" }) {
            ...fluidImage
          }
          dutchHero: file(relativePath: { eq: "amsterdam-hero.jpg" }) {
            ...fluidImage
          }
        }
      `}
      render={data => {
        return (
          <Img
            className={styles.hero}
            fadeIn={false}
            fluid={data[identifier].childImageSharp.fluid}
          />
        )
      }}
    />
  )
}

export default Image
