import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Image from '../components/image'
import SEO from '../components/seo'
import PhoneImage from '../components/phone-image'
import AppIcon from '../components/app-icon'
import StoreIcon from '../components/store-icon'

const apps = {
  nl: [
    {
      icon: 'netherlands',
      title: 'De nummer 1 App voor het CBR theorie examen!',
      features: [
        'Hetzelfde opgebouwd als de examens van het cbr – inclusief uitleg',
        'Gebruik de testmodus om een echt examen te simuleren.',
        'En nog veel meer.',
      ],
      image: 'phoneNL',
      links: {
        ios: 'https://itunes.apple.com/nl/app/id532549685?mt=8',
        android:
          'https://play.google.com/store/apps/details?id=ch.swift.itheorienl&hl=nl',
      },
    },
    {
      icon: 'belgium',
      image: 'phoneBENL',
      title:
        'Met iTheorie heb je alle vragen die gesteld kunnen worden op de theorietoets in je zak.',
      features: [
        'iTheorie bevat de volledige lijst met vragen voor het theoretische rijexamen.',
        'In de examenmodus kunt u de werkelijke examensituatie simuleren',
        'En nog veel meer.',
      ],
      links: {
        ios: 'https://itunes.apple.com/be/app/id502454853?mt=8',
        android:
          'https://play.google.com/store/apps/details?id=ch.swift.itheoriebe',
      },
    },
  ],
  fr: [
    {
      icon: 'belgium',
      image: 'phoneBEFR',
      title:
        'Avec iThéorie, tu as en poche toutes les questions susceptibles d’être posées à l’examen de théorie.',
      features: [
        'iThéorie contient la liste complète des questions de l’examen théorique de la conduite.',
        'En mode Examen, tu peux simuler la situation réelle d’examen.',
        'Et beaucoup plus.',
      ],
      links: {
        ios: 'https://itunes.apple.com/be/app/id502454853?mt=8',
        android:
          'https://play.google.com/store/apps/details?id=ch.swift.itheoriebe',
      },
    },
  ],
}

const IndexPage = ({ data, pageContext }) => {
  const { language, originalPath } = pageContext
  return (
    <Layout language={language} path={originalPath}>
      <SEO
        title={data.site.siteMetadata.title}
        keywords={[`gatsby`, `application`, `react`]}
      />
      <div
        style={{
          width: `100vw`,
          marginBottom: `1.45rem`,
          position: 'relative',
          left: '50%',
          right: '50%',
          marginLeft: '-50vw',
          marginRight: '-50vw',
        }}
      >
        <Image identifier={language === 'nl' ? 'dutchHero' : 'frenchHero'} />
      </div>

      {apps[language].map((app, i) => {
        return (
          <div
            style={{
              marginTop: '4em',
              marginBottom: '3em',
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
            key={i}
          >
            <PhoneImage identifier={app.image} />
            <div style={{ flex: 1, paddingLeft: '2em', paddingRight: '2em' }}>
              <h2>{app.title}</h2>
              <ul>
                {app.features.map((feat, featKey) => (
                  <li key={featKey}>{feat}</li>
                ))}
              </ul>
              <AppIcon identifier={app.icon} />
              <div style={{ flex: 'row', marginTop: '2em' }}>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'none' }}
                  href={app.links.ios}
                >
                  <StoreIcon identifier="ios" />{' '}
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'none' }}
                  href={app.links.android}
                >
                  <StoreIcon identifier="android" />{' '}
                </a>
              </div>
            </div>
          </div>
        )
      })}
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default IndexPage
